import axios from 'axios'
import settings from '../settings/apis.js'

class PrintOfferApi {
    constructor() {
        this.Api = axios.create({
            baseURL: settings[process.env.NODE_ENV].printTemplates + '/offer/',
            withCredentials: false,
            headers: {
                'Accept': 'application/octet-stream',
            }
        });
    }

    getOfferForPrint(offerType, offerCode) {
        if (offerCode == "") {
            return this.Api.get('PrintOfferPdfget?offerType=' + offerType, {responseType: 'blob'});
        }
        else {
            return this.Api.get('PrintOfferPdfget?offerType=' + offerType + "_" + offerCode, {responseType: 'blob'});

        }
    }
    getOfferForPrintWithDateFilter(offerType, offerCode, dateFilter) {
        let month = dateFilter.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = dateFilter.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var date = day + "-" + month + "-" + dateFilter.getFullYear();
        return this.Api.get('PrintOfferPdfSave?offerType=' + offerType + "_" + offerCode + "&dateFilter=" + date, {responseType: 'blob'});
    }
}

export {
    PrintOfferApi
}