<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.currentOffer') }}</h5>
                <Toast />
                <Fieldset :legend="$t('sportsbets.printOfferSettings')" :toggleable="true" :collapsed="true" class="p-col-12 p-mb-2">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-2">
                            <span class="p-float-label">
                                <Dropdown
                                    id="manualOddsDropDown"
                                    style="width: 100%"
                                    v-model="printOfferWithDateTemplateModel"
                                    :options="printOfferWithDateTemplates"
                                    optionLabel="name"
                                    :placeholder="$t('dropdown.placeholder.printTemplate')"
                                    :filter="!this.isMobile()"
                                />
                                <label for="manualOddsDropDown">{{ $t('dropdown.placeholder.printTemplate') }}</label>
                            </span>
                        </div>
                        <div class="p-col-12 p-md-2">
                            <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showIcon="true" :showButtonBar="true" v-model="printDateModel"></Calendar>
                        </div>
                        <div class="p-col-12 p-md-2 p-text-right">
                            <Button :label="$t('buttons.saveAsPdf')" class="p-button-raised p-button-warning" @click="getOfferWithDateFilter()" />
                            <i class="pi pi-spin pi-spinner p-ml-2" style="font-size: 1.5rem" v-show="!isSaveOfferDone"></i>
                        </div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-12 p-md-2">
                            <span class="p-float-label">
                                <Dropdown
                                    id="manualOddsDropDown"
                                    style="width: 100%"
                                    v-model="printOfferTemplateModel"
                                    :options="printOfferTemplates"
                                    optionLabel="name"
                                    :placeholder="$t('dropdown.placeholder.printTemplate')"
                                    :filter="!this.isMobile()"
                                />
                                <label for="manualOddsDropDown">{{ $t('dropdown.placeholder.printTemplate') }}</label>
                            </span>
                        </div>
                        <div class="p-col-12 p-md-2 p-text-right p-md-offset-2">
                            <Button :label="$t('buttons.saveAsPdf')" class="p-button-raised p-button-warning" @click="getPrintOffer()" />
                        </div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-12 p-md-2">
                            <Button type="button" :loading="publishOfferInProgress" :label="$t('buttons.publishOffer')" class="p-button-raised p-button-info" @click="publishOffer()" />
                        </div>
                    </div>
                </Fieldset>
                <TabView v-model:activeIndex="activeTab">
                    <TabPanel :header="$t('sportsbets.fixtures')">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-2">
                                <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="sportsModel" :options="sports" optionLabel="displayName" :placeholder="$t('dropdown.placeholder.sport')" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="categoriesModel" v-show="showCategoryDdl" :options="categories" optionLabel="displayName" :placeholder="$t('dropdown.placeholder.category')" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="tournamentsModel" v-show="showTournamentsDdl" :options="tournaments" optionLabel="displayName" :placeholder="$t('dropdown.placeholder.tournament')" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2 p-text-right"><Button v-show="showFetchBtn" :label="$t('buttons.getFixtures')" class="p-button-raised p-button-success" @click="readItems()" /></div>
                        </div>
                        <div class="p-grid" v-show="showFixturesTable">
                            <div class="p-col-12 p-md-2">
                                <span class="p-float-label">
                                    <Dropdown id="inOfferDropDown" style="width: 100%" v-model="inOfferModel" :options="inOfferOptions" optionLabel="Name" :placeholder="$t('dropdown.placeholder.inOffer')" />
                                    <label for="inOfferDropDown">{{ $t('dropdown.placeholder.inOffer') }}</label>
                                </span>
                            </div>
                            <div class="p-col-12 p-md-2">
                                <span class="p-float-label">
                                    <Dropdown id="manualOddsDropDown" style="width: 100%" v-model="manualOddsModel" :options="manualOddsOptions" optionLabel="Name" :placeholder="$t('dropdown.placeholder.manualOdds')" />
                                    <label for="manualOddsDropDown">{{ $t('dropdown.placeholder.manualOdds') }}</label>
                                </span>
                            </div>
                        </div>
                        <DataTable
                            v-if="showFixturesTable"
                            :value="filteredData"
                            :paginator="true"
                            :rows="perPage"
                            :loading="loading"
                            ref="datatable"
                            responsiveLayout="scroll"
                            :paginatorBottom="true"
                            stateStorage="session"
                            stateKey="dt-state-crtoffer"
                        >
                            <template #header> </template>
                            <Column
                                ><template #body="slotProps">
                                    <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                                </template>
                            </Column>
                            <Column :header="$t('sportsbets.inOffer')">
                                <template #body="{ data }">
                                    <Button :icon="'pi pi-' + inOfferIcon(data.inOffer)" :class="'p-button-rounded p-button-' + inOfferColor(data.inOffer) + ' p-mr-2'" />
                                </template>
                                ></Column
                            >
                            <Column :header="$t('sportsbets.oddsUpdateStatus')">
                                <template #body="{ data }">
                                    <Button :label="oddsStatusText(data.manualOdds)" :class="'p-button-outlined p-mr-2 p-mb-2 p-button-' + oddsStatusColor(data.manualOdds) + ' p-mr-2'" />
                                </template>
                                ></Column
                            >
                            <Column field="offerNo" :header="$t('sportsbets.offerNo')" :sortable="true"></Column>
                            <Column field="fixtureId" :header="$t('sportsbets.fixtureId')" :sortable="true">  </Column>
                            <Column field="fixtureDate" :header="$t('sportsbets.date')" :sortable="true">
                                <template #body="{ data }"> {{ formatCSDateAndTime(data.fixtureDate) }} </template>
                            </Column>
                            <Column field="tournament.name" :header="$t('sportsSettings.tournament')">
                                <template #body="{ data }">{{ tournamentsDictionary[data.tournamentId] }}</template>
                            </Column>
                            <Column field="competitor1" :header="$t('sportsbets.homeId')" :sortable="true"></Column>
                            <Column field="competitor2" :header="$t('sportsbets.awayId')" :sortable="true"></Column>
                            <Column field="name" :header="$t('sportsSettings.name')"> </Column>
                            <Column :header="$t('sportsbets.printSelection')">
                                <template #body="{ data }">
                                    <Button
                                        :class="'p-button-text p-mr-2 p-mt-2 p-button-' + printSelectionColor(data.printSelection)"
                                        :label="printSelectionText(data.printSelection)"
                                        @click="updatePrintSelection(data.fixtureId, !data.printSelection)"
                                    />
                                </template>
                            </Column>
                            <Column>
                                <template #body="{ data }">
                                    <Button :label="$t('buttons.showTickets')" class="p-button-text p-button-plain p-button-info p-mr-2 p-mb-2" @click="goto('sportBetsTickets', { backRoute: this.crtRoute, redirectFixtureId: data.fixtureId })" />
                                </template>
                            </Column>
                            <Column class="p-text-right">
                                <template #body="{ data }">
                                    <Button
                                        icon="pi pi-pencil"
                                        class="p-button-rounded p-button-warning p-mr-2"
                                        @click="goto('eventDetails', { id: data.fixtureId, fixtureId: data.fixtureId, isTopOffer: false, fixturesActive: fixturesActive, backRoute: crtRoute })"
                                    />
                                </template>
                            </Column>
                        </DataTable>
                    </TabPanel>
                    <TabPanel :header="$t('sportsbets.outrights')">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="sportsModel" :options="sports" optionLabel="displayName" :placeholder="$t('dropdown.placeholder.sport')" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2 p-md-offset-8 p-text-right">
                                <Button v-show="showFetchBtn" :label="fixturesActive ? $t('buttons.getFixtures') : $t('buttons.getOutrights')" class="p-button-raised p-button-success" @click="readItems()" />
                            </div>
                        </div>
                        <div class="p-grid" v-show="showOutrightsTable">
                            <div class="p-col-12 p-md-2">
                                <span class="p-float-label">
                                    <Dropdown id="inOfferDropDown" style="width: 100%" v-model="inOfferModel" :options="inOfferOptions" optionLabel="Name" :placeholder="$t('dropdown.placeholder.inOffer')" />
                                    <label for="inOfferDropDown">{{ $t('dropdown.placeholder.inOffer') }}</label>
                                </span>
                            </div>
                            <div class="p-col-12 p-md-2">
                                <span class="p-float-label">
                                    <Dropdown id="manualOddsDropDown" style="width: 100%" v-model="manualOddsModel" :options="manualOddsOptions" optionLabel="Name" :placeholder="$t('dropdown.placeholder.manualOdds')" />
                                    <label for="manualOddsDropDown">{{ $t('dropdown.placeholder.manualOdds') }}</label>
                                </span>
                            </div>
                        </div>
                        <DataTable v-if="showOutrightsTable" :value="filteredDataOutrights" :paginator="true" :rows="perPage" :loading="loading" class="p-mt-3" ref="datatable" responsiveLayout="scroll" :paginatorBottom="true">
                            <template #header> </template>
                            <Column
                                ><template #body="slotProps">
                                    <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                                </template>
                            </Column>
                            <Column :header="$t('sportsbets.inOffer')">
                                <template #body="{ data }">
                                    <Button :icon="'pi pi-' + inOfferIcon(data.inOffer)" :class="'p-button-rounded p-button-' + inOfferColor(data.inOffer) + ' p-mr-2'" />
                                </template>
                                ></Column
                            >
                            <Column :header="$t('sportsbets.oddsUpdateStatus')">
                                <template #body="{ data }">
                                    <Button :label="oddsStatusText(data.manualOdds)" :class="'p-button-outlined p-mr-2 p-mb-2 p-button-' + oddsStatusColor(data.manualOdds) + ' p-mr-2'" />
                                </template>
                                ></Column
                            >
                            <Column field="offerNumber" :header="$t('sportsbets.offerNo')" :sortable="true"></Column>
                            <Column field="name" :header="$t('sportsSettings.name')"> </Column>
                            <Column field="startDate" :header="$t('sportsbets.startDate')" :sortable="true">
                                <template #body="{ data }"> {{ formatCSDateAndTime(data.startDate) }} </template>
                            </Column>
                            <Column field="endDate" :header="$t('sportsbets.endDate')" :sortable="true">
                                <template #body="{ data }"> {{ formatCSDateAndTime(data.endDate) }} </template>
                            </Column>
                            <Column field="markets" :header="$t('sportsSettings.marketsNo')">
                                <template #body="{ data }"> {{ data.markets.length }} </template></Column
                            >
                            <Column class="p-text-right">
                                <template #body="{ data }">
                                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" @click="goto('eventDetails', { id: data.id, fixtureId: data.id, fixturesActive: fixturesActive, backRoute: crtRoute })" /> </template
                            ></Column>
                        </DataTable>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    </div>
</template>
<script>
import { SportsApi } from '../../service/SportsApi';
import { BoSportsApi } from '../../service/BoSportsApi';
import { PrintOfferApi } from '../../service/PrintOfferApi';

import { uiSettings } from '../../settings/uiSettings';
import { store } from '../../store/index';
export default {
    name: 'SportsOffer',
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            items: [],
            itemsOutrights: [],
            SportsApi: new SportsApi(),
            BoSportsApi: new BoSportsApi(),
            PrintOfferApi: new PrintOfferApi(),
            startDateModel: null,
            endDateModel: null,
            printDateModel: null,
            perPage: uiSettings.tableRows,
            loading: true,
            sports: [],
            sportsModel: {},
            categories: [],
            categoriesModel: {},
            tournaments: [],
            tournamentsModel: {},
            showFetchBtn: false,
            tournamentsDictionary: [],
            showCategoryDdl: false,
            showTournamentsDdl: false,
            filtersInitializationDone: false,
            defaultEntityId: 0,
            showFixturesTable: false,
            activeTab: 0,
            showOutrightsTable: false,
            errorKeyCount: 0,
            crtRoute: 'currentOffer',
            inOfferModel: { Name: 'All', Id: '0' },
            inOfferOptions: [
                { Name: 'All', Id: '0' },
                { Name: 'In Offer', Id: '1' },
                { Name: 'Out of Offer', Id: '2' },
            ],
            manualOddsModel: { Name: 'All', Id: '0' },
            manualOddsOptions: [
                { Name: 'All', Id: '0' },
                { Name: 'Manual Odds', Id: '1' },
                { Name: 'Auto Odds', Id: '2' },
            ],
            printOfferTemplates: [
                { ddlid: 1, id: 1, name: 'Football today', code: '' },
                { ddlid: 2, id: 2, name: 'All Sports today', code: '' },
                { ddlid: 3, id: 3, name: 'Football all', code: '' },
                { ddlid: 4, id: 4, name: 'All offer', code: '' },
                { ddlid: 5, id: 5, name: 'Football chronologic', code: '' },
                { ddlid: 10, id: 8, name: 'Tennis Main', code: 'Main' },
                {
                    ddlid: 18,
                    id: 12,
                    name: 'Other Sports Today',
                    code: '',
                },
                {
                    ddlid: 19,
                    id: 13,
                    name: 'Other Sports',
                    code: '',
                },
                {
                    ddlid: 20,
                    id: 14,
                    name: 'Specials',
                    code: '',
                },
            ],
            printOfferWithDateTemplates: [
                {
                    ddlid: 1,
                    id: 15,
                    name: 'Date Selection List Popular',
                    code: 'Popular',
                },
                {
                    ddlid: 2,
                    id: 15,
                    name: 'Date Selection List Halves',
                    code: 'Halves',
                },
                { ddlid: 3, id: 15, name: 'Date Selection List Combo', code: 'Combo' },
                { ddlid: 4, id: 15, name: 'Date Selection List Combo', code: 'Combo2' },
            ],
            printOfferTemplateModel: {},
            printOfferWithDateTemplateModel: {},
            publishOfferInProgress: false,
            isSaveOfferDone: true,
        };
    },
    props: {},
    computed: {
        storeFilterData() {
            return store.getters['filters/filter'](this.crtRoute);
        },
        startDateApi() {
            return this.formatApiDate(this.startDateModel);
        },
        endDateApi() {
            return this.formatApiDate(this.endDateModel);
        },
        fixturesActive() {
            return this.activeTab == 0;
        },
        filteredData() {
            return this.filterItems(this.items);
        },
        filteredDataOutrights() {
            return this.filterItems(this.itemsOutrights);
        },
    },
    watch: {
        activeTab() {
            this.getSports();
            this.inOfferModel = { Name: 'All', Id: '0' };
            this.manualOddsModel = { Name: 'All', Id: '0' };
        },
        startDateModel() {
            this.showFixturesTable = false;
            this.sportsModel = {};
            if (this.filtersInitializationDone) {
                this.getSports();
            }
        },
        endDateModel() {
            this.showFixturesTable = false;
            this.sportsModel = {};
            if (this.filtersInitializationDone) {
                this.getSports();
            }
        },
        sportsModel() {
            this.clearFixturesTable();
            this.clearCategoriesDdl();
            this.clearTournamentsDdl();
            this.showTournamentsDdl = false;
            this.showCategoryDdl = false;
            this.showFetchBtn = false;
            this.showFixturesTable = false;

            if (this.notNullOrEmptyObject(this.sportsModel)) {
                if (this.fixturesActive) {
                    this.getCategories();
                } else {
                    this.showFetchBtn = true;
                    this.showCategoryDdl = false;
                    this.showTournamentsDdl = false;
                }
            }
        },
        categoriesModel() {
            this.clearFixturesTable();
            this.clearTournamentsDdl();
            this.showFixturesTable = false;
            this.showTournamentsDdl = false;
            if (this.notNullOrEmptyObject(this.categoriesModel)) {
                this.showFetchBtn = true;
                this.getTournaments();
            }
        },
        tournamentsModel() {
            if (this.notNullOrEmptyObject(this.tournamentsModel)) {
                this.selectedIds = [];
                this.readItems();
            }
        },
    },
    mounted() {
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var startDateString = year + '-' + month + '-' + day + 'T00:00';
        this.printDateModel = new Date(startDateString);
        if (this.storeFilterData != undefined && this.notNullOrEmptyField(this.storeFilterData.data.startDate) && this.notNullOrEmptyField(this.storeFilterData.data.endDate)) {
            this.startDateModel = new Date(this.storeFilterData.data.startDate);
            this.endDateModel = new Date(this.storeFilterData.data.endDate);
        } else {
            const tomorrow = new Date(crtDate);
            tomorrow.setDate(tomorrow.getDate() + 1);
            let yearT = tomorrow.getFullYear();
            let monthT = tomorrow.getMonth() + 1;
            if (monthT <= 9) {
                monthT = '0' + monthT;
            }
            let dayT = tomorrow.getDate();
            if (dayT <= 9) {
                dayT = '0' + dayT;
            }
            var endDateString = yearT + '.' + monthT + '.' + dayT + ' 10:00';
            this.startDateModel = new Date(startDateString);
            this.endDateModel = new Date(endDateString);
        }
        this.getSports();
    },
    methods: {
        composeAndSaveFiltersToStore() {
            let filters = {
                startDate: this.startDateModel,
                endDate: this.endDateModel,
                sportId: this.notNullOrEmptyObject(this.sportsModel) ? this.sportsModel.id : this.defaultEntityId,
                categoryId: this.notNullOrEmptyObject(this.categoriesModel) ? this.categoriesModel.id : this.defaultEntityId,
                tournamentId: this.notNullOrEmptyObject(this.tournamentsModel) ? this.tournamentsModel.id : this.defaultEntityId,
            };
            this.saveFiltersToStore(filters, this.crtRoute);
        },
        readItems() {
            this.loading = true;
            this.showNoEvents = false;
            if (this.fixturesActive) {
                var tournamentId = this.notNullOrEmptyObject(this.tournamentsModel) ? this.tournamentsModel.id : this.defaultEntityId;
                this.SportsApi.getFixtures(this.sportsModel.id, this.categoriesModel.id, tournamentId, this.startDateApi, this.endDateApi)
                    .then((response) => {
                        this.composeAndSaveFiltersToStore();
                        this.items = response.data;
                        this.loading = false;
                        this.showFixturesTable = true;
                    })
                    .catch((error) => {
                        if (error.response.status == 404) {
                            this.showNoEvents = true;
                            this.showFixturesTable = false;
                            this.errorKeyCount++;
                        } else {
                            this.showToast('error', this.$t('alerts.generalError'));
                        }
                    });
            } else {
                this.SportsApi.getOutrights(this.sportsModel.id)
                    .then((response) => {
                        this.itemsOutrights = response.data;
                        this.showOutrightsTable = true;
                        this.loading = false;
                    })
                    .catch((error) => {
                        if (error.response.status == 404) {
                            this.showNoEvents = true;
                            this.showFixturesTable = false;
                            this.errorKeyCount++;
                        } else {
                            this.showToast('error', this.$t('alerts.generalError'));
                        }
                    });
            }
        },
        getSports() {
            this.sportsModel = {};
            this.showFetchBtn = false;
            this.showNoEvents = false;
            if (this.fixturesActive) {
                this.SportsApi.getSports(this.startDateApi, this.endDateApi)
                    .then((response) => {
                        this.sports = response.data;
                        if (this.sports.length > 0) {
                            if (!this.filtersInitializationDone && this.storeFilterData != undefined) {
                                this.sportsModel = this.sports.find((s) => s.id == this.storeFilterData.data.sportId);
                            } else {
                                this.filtersInitializationDone = true;
                            }
                        } else {
                            this.filtersInitializationDone = true;
                        }
                    })
                    .catch((error) => {
                        if (error.response.status == 404) {
                            this.showNoEvents = true;
                            this.showFixturesTable = false;
                            this.errorKeyCount++;
                        } else {
                            this.showToast('error', this.$t('alerts.generalError'));
                        }
                    });
            } else {
                this.SportsApi.getOutrightsSports(this.startDateApi, this.endDateApi)
                    .then((response) => {
                        this.sports = response.data;
                        this.filtersInitializationDone = true;
                    })
                    .catch((error) => {
                        if (error.response.status == 404) {
                            this.showNoEvents = true;
                            this.showOutrightsTable = false;
                            this.errorKeyCount++;
                        } else {
                            this.showToast('error', this.$t('alerts.generalError'));
                        }
                    });
            }
        },
        showToast(toastSeverity, message) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: message, life: this.getToastLifeSeconds() });
        },
        getCategories() {
            this.showFetchBtn = false;
            this.showCategoryDdl = false;
            this.showTournamentsDdl = false;
            this.showNoEvents = false;
            this.SportsApi.getCategories(this.sportsModel.id, this.startDateApi, this.endDateApi)
                .then((response) => {
                    this.categories = response.data;
                    this.showCategoryDdl = true;
                    this.showTournamentsDdl = false;
                    if (this.categories.length > 0) {
                        if (!this.filtersInitializationDone && this.storeFilterData != undefined) {
                            this.categoriesModel = this.categories.find((s) => s.id == this.storeFilterData.data.categoryId);
                        } else {
                            this.filtersInitializationDone = true;
                        }
                    } else {
                        this.filtersInitializationDone = true;
                    }
                })
                .catch((error) => {
                    if (error.response.status == 404) {
                        this.showNoEvents = true;
                        this.showOutrightsTable = false;
                        this.errorKeyCount++;
                    } else {
                        this.showToast('error', this.$t('alerts.generalError'));
                    }
                });
        },
        getTournaments() {
            this.showTournamentsDdl = false;
            this.showNoEvents = false;
            this.SportsApi.getTournaments(this.sportsModel.id, this.categoriesModel.id, this.startDateApi, this.endDateApi)
                .then((response) => {
                    this.tournaments = response.data;
                    this.createTournamentsDictionary();
                    this.showTournamentsDdl = true;
                    if (this.tournaments.length > 0) {
                        if (!this.filtersInitializationDone && this.storeFilterData != undefined) {
                            this.tournamentsModel = this.tournaments.find((s) => s.id == this.storeFilterData.data.tournamentId);
                        }
                    }
                    this.filtersInitializationDone = true;
                })
                .catch((error) => {
                    if (error.response.status == 404) {
                        this.showNoEvents = true;
                        this.showOutrightsTable = false;
                        this.errorKeyCount++;
                    } else {
                        this.showToast('error', this.$t('alerts.generalError'));
                    }
                });
        },
        createTournamentsDictionary() {
            for (let i = 0; i < this.tournaments.length; i++) {
                this.tournamentsDictionary[this.tournaments[i].id] = this.tournaments[i].displayName;
            }
        },
        clearFixturesTable() {
            this.items = [];
            this.showFixturesTable = false;
        },
        clearCategoriesDdl() {
            this.categories = [];
            this.categoriesModel = {};
        },
        clearTournamentsDdl() {
            this.tournaments = [];
            this.tournamentsModel = {};
        },
        closeModal() {
            this.showModal = false;
        },
        inOfferIcon(inOffer) {
            return inOffer == true ? 'check' : 'times';
        },
        inOfferColor(inOffer) {
            return inOffer == true ? 'success' : 'danger';
        },
        oddsStatusColor(manualOdds) {
            return manualOdds == true ? 'warning' : 'success';
        },
        oddsStatusText(manualOdds) {
            return manualOdds == true ? 'manual' : 'auto';
        },
        printSelectionColor(printSelection) {
            return printSelection == true ? 'danger' : 'success';
        },
        printSelectionText(printSelection) {
            return printSelection == true ? this.$t('general.remove') : this.$t('general.add');
        },
        filterItems(crtItems) {
            let manualOdds = this.manualOddsModel.Id === '1' ? true : false;
            let inOffer = this.inOfferModel.Id === '1' ? true : false;
            if (this.inOfferModel.Id === '0') {
                if (this.manualOddsModel.Id === '0') {
                    return crtItems;
                } else {
                    return crtItems.filter((item) => {
                        return item.manualOdds === manualOdds;
                    });
                }
            } else {
                if (this.manualOddsModel.Id === '0') {
                    return crtItems.filter((item) => {
                        return item.inOffer === inOffer;
                    });
                } else {
                    return crtItems.filter((item) => {
                        return item.manualOdds === manualOdds && item.inOffer === inOffer;
                    });
                }
            }
        },
        getOfferWithDateFilter() {
            this.isSaveOfferDone = false;
            if (this.notNullOrEmptyObject(this.printOfferWithDateTemplateModel)) {
                this.PrintOfferApi.getOfferForPrintWithDateFilter(this.printOfferWithDateTemplateModel.id, this.printOfferWithDateTemplateModel.code, this.printDateModel)
                    .then((response) => {
                        this.isSaveOfferDone = true;
                        let blob = new Blob([response.data], { type: 'application/pdf' });
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = 'DatePrintedOffer' + this.printOfferWithDateTemplateModel.id + this.printOfferWithDateTemplateModel.code + '_' + this.printDateModel;
                        ('.pdf');
                        link.click();
                    })
                    .catch(() => {
                        this.showToast('error', this.$t('alerts.generalError'));
                    });
            } else {
                this.showToast('error', this.$t('sportsbets.alerts.noPrintTemplate'));
            }
        },
        publishOffer() {
            this.publishOfferInProgress = true;
            this.BoSportsApi.publishOffer()
                .then(() => {
                    this.publishOfferInProgress = false;
                    this.showToast('success', this.$t('sportsbets.alerts.publishDone'));
                })
                .catch(() => {
                    this.publishOfferInProgress = false;
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        getPrintOffer() {
            if (this.notNullOrEmptyObject(this.printOfferTemplateModel)) {
                this.PrintOfferApi.getOfferForPrint(this.printOfferTemplateModel.id, this.printOfferTemplateModel.code)
                    .then((response) => {
                        let blob = new Blob([response.data], { type: 'application/pdf' });
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = 'PrintedOffer' + this.printOfferTemplateModel.id + this.printOfferTemplateModel.code + '.pdf';
                        link.click();
                    })
                    .catch(() => {
                        this.showToast('error', this.$t('alerts.generalError'));
                    });
            } else {
                this.showToast('error', this.$t('sportsbets.alerts.noPrintTemplate'));
            }
        },
        updatePrintSelection(fixtureId, newStatus) {
            this.BoSportsApi.updatePrintSelection(fixtureId, newStatus)
                .then(() => {
                    this.readItems();
                })
                .catch((error) => {
                    this.checkForUnauthorizeError(error);
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
    },
};
</script>
